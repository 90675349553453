import React, { useState } from "react"
import Layout from "../../../containers/layout"
import Ad from "../../../containers/Ad"
import FormationFinder from "../../../containers/FormationFinder"
import ThreeVFinder from "../../../containers/ThreeVFinder"
import { getQueryString, assign } from "../../../helpers"
import BoxButton from "../../../components/BoxButton"
import { date2String } from "../../../helpers/datetime"

const Home = () => {
  const qs = getQueryString()

  const modes = [
    {
      label: "Formation",
      component: <FormationFinder />,
    },
    {
      label: "3V Method 1",
      component: <ThreeVFinder isoDate={qs.dt || date2String(new Date())} />,
    },
    {
      label: "3V Method 2",
      component: (
        <ThreeVFinder
          useMethod={qs.mode === "2"}
          isoDate={qs.dt || date2String(new Date())}
        />
      ),
    },
  ]
  const [mode] = useState(modes[parseInt(qs.mode)] ? parseInt(qs.mode) : 0)

  return (
    <Layout>
      <BoxButton
        activeIndex={mode}
        onClick={(e, i) => {
          assign(`?mode=${i}`)
        }}
        buttons={modes}
      />
      {modes[mode].component}
      <Ad client="ca-pub-6343289823091285" slot="5114483936" />
    </Layout>
  )
}

export default Home
