import React, { useState, useEffect } from "react"
import {
  lookupChart,
  getMonthChartByDate,
  getDayChartByDate,
  isDivineLightDay,
  getZhiRunHourChart,
  isDivineBlessingDay,
  isDivineForceDay,
  findChiefPalace,
} from "../cm-lib/QiMen"
import { parseISO, getDaysInMonth, addMonths, format } from "date-fns"
import BoxButton from "../components/BoxButton"
import { Box, Text } from "grommet"
import DateTimePicker from "../components/DateTimePicker"
import { assign } from "../helpers"
import { date2String } from "../helpers/datetime"

const Component = ({ sector, type, isoDate, useMethod }) => {
  const [result, setResult] = useState([])
  const months = [...Array(2).keys()].map(e => ({
    value: format(addMonths(new Date(), e), "yyyy-MM-dd"),
    label: format(addMonths(new Date(), e), "MMM"),
  }))
  const [selectedMonth, setSelectedMonth] = useState(new Date())
  const [method] = useState(useMethod)
  const natalDate = parseISO(isoDate)
  const natalChart = getZhiRunHourChart(natalDate)
  const [activeMonthIndex, setActiveMonthIndex] = useState(0)
  useEffect(() => {
    const days = getDaysInMonth(selectedMonth)

    const found = [...Array(days).keys()]
      .map(e => {
        const d = e + 1
        selectedMonth.setDate(d)
        const monthChartObj = getMonthChartByDate(selectedMonth)
        const monthChart = lookupChart(
          monthChartObj.structure,
          monthChartObj.pillar
        )
        const dayChartObject = getDayChartByDate(selectedMonth)
        const dayChart = lookupChart(
          dayChartObject.structure,
          dayChartObject.pillar
        )
        const isDivineLightDate = !method
          ? isDivineLightDay(
              natalChart.p.divineLight,
              monthChart.chart,
              dayChart.chart
            )
          : isDivineLightDay(
              findChiefPalace(natalChart.ju.chart).palace,
              monthChart.chart,
              dayChart.chart
            )
        return {
          date: selectedMonth.getDate(),
          isDivineLightDate,
          isDivineBlessDate: isDivineBlessingDay(
            natalChart.p.divineLight,
            monthChart.chart,
            dayChart.chart
          ),
          isDivineForceDate: isDivineForceDay(
            natalChart.p.divineLight,
            monthChart.chart,
            dayChart.chart
          ),
        }
      })
      .filter(
        e =>
          !(
            !e.isDivineLightDate &&
            !e.isDivineBlessDate &&
            !e.isDivineForceDate
          )
      )
    setResult(found)
  }, [selectedMonth])

  return (
    <>
      <br />
      <Text>Date Of Birth</Text>
      <DateTimePicker
        label="Find 3V"
        onClick={date => assign(`?dt=${date2String(date)}&mode=1`)}
        dateObject={natalDate}
      />
      <br />
      <BoxButton
        activeIndex={activeMonthIndex}
        onClick={(e, i) => (
          setActiveMonthIndex(i), setSelectedMonth(parseISO(e.value))
        )}
        buttons={months}
      />
      {result.length
        ? result.map((e, i) => (
            <Box key={i}>
              {e.isDivineLightDate && <Box> {e.date} Divine Light</Box>}
              {e.isDivineBlessingDate && <Box> {e.date} Divine Blessing</Box>}
              {e.isDivineForceDate && <Box> {e.date} Divine Force</Box>}
            </Box>
          ))
        : "No Dates Found"}
    </>
  )
}

export default Component
