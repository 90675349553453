import React, { useState, useEffect } from "react"
import { Anchor, Box, Select, Button, Text } from "grommet"
import { addDays, format } from "date-fns"
import { getQueryString, assign, parseStringDate } from "../helpers"
import { formation } from "../cm-lib/reference/Formation"

import { searchHourChart } from "../cm-lib/QiMen"
import { useTranslation } from "react-i18next"
import {
  DATETIME_FORMAT_ROUND_DOWN,
  READABLE_DATETIME_FORMAT_ROUND_DOWN,
} from "../constants"
const SEARCH_INTERVAL = 3

const Home = () => {
  const qs = getQueryString()
  const startDateObject = parseStringDate(qs.start, new Date())
  const { t } = useTranslation()
  const [selectedFormation, setSelectedFormation] = useState(qs.f || "")
  const [startDate] = useState(
    startDateObject > new Date() ? new Date() : startDateObject
  )
  const [futureDate] = useState(addDays(startDate, SEARCH_INTERVAL))
  const [foundList, setFoundList] = useState([])

  useEffect(() => {
    setFoundList(searchHourChart(startDate, futureDate, selectedFormation))
  }, [startDate])
  return (
    <>
      <Text>
        {" "}
        Help us to get more{" "}
        <Anchor href="https://www.facebook.com/openfengshui/" target="_blank">
          likes
        </Anchor>{" "}
        for our facebook Page to increase the number of search days. We will
        increase to 7 days when we hit 1500 likes. Thanks!
      </Text>

      <h2>Search Hour Chart for next {SEARCH_INTERVAL} days</h2>

      <Box direction="row">
        <Select
          options={Object.keys(formation).map(k => ({
            label: `${k} ${t(k)}`,
            value: k,
          }))}
          placeholder="Formation"
          labelKey="label"
          valueKey={{ key: "value", reduce: true }}
          value={selectedFormation}
          onChange={({ value: nextValue }) => setSelectedFormation(nextValue)}
        />

        <Button
          disabled={!selectedFormation}
          primary
          label="Search"
          onClick={date => {
            const start = format(startDate, DATETIME_FORMAT_ROUND_DOWN)
            assign(`?start=${start}&f=${selectedFormation}`)
          }}
        />
      </Box>
      {foundList && foundList.length
        ? foundList.map(e => (
            <Box>
              <Anchor
                href={`/?dt=${format(e, DATETIME_FORMAT_ROUND_DOWN)}`}
                label={format(e, READABLE_DATETIME_FORMAT_ROUND_DOWN)}
              />
            </Box>
          ))
        : "No Dates Found"}
    </>
  )
}

export default Home
