export const formation = {
  青龍返首: ({ heaven, earth, door, god, star }) =>
    heaven === "戊" && earth === "丙",
  飞鸟跌穴: ({ heaven, earth, door, god, star }) =>
    heaven === "丙" && earth === "戊",
  玉女守門: ({ heaven, envoy }) => {
    return !![heaven === "丁" && envoy].find(o => o)
  },
  雲遁: ({ heaven, earth, door, god, star }, i) => {
    return !![
      heaven === "乙" &&
        earth === "辛" &&
        (door === "休" || door === "生" || door === "開"),
      heaven === "乙" && (door === "休" || door === "開") && i === 2,
    ].find(o => o)
  },
  龍遁: ({ heaven, earth, door, god, star }, i) => {
    return !![
      heaven === "乙" &&
        (door === "休" || door === "開") &&
        (i === 7 || i === 8),
    ].find(o => o)
  },
  天遁: ({ heaven, earth, door, god, star }) => {
    return !![
      heaven === "丙" && earth === "丁" && door === "生",
      heaven === "丙" && earth === "戊" && door === "生",
      heaven === "丙" && door === "開",
    ].find(o => o)
  },
  地遁: ({ heaven, earth, door, god, star }) =>
    heaven === "乙" && earth === "己" && door === "開",
  人遁: ({ heaven, earth, door, god, star }) =>
    heaven === "丁" && god === "休" && door === "陰",
  風遁: ({ heaven, earth, door, god, star }, i) => {
    return !![
      heaven === "乙" &&
        (door === "休" || door === "開" || door === "生") &&
        i === 0,
      (heaven === "丙" && door === "開") || i === 0,
    ].find(o => o)
  },
  虎遁: ({ heaven, earth, door, god, star }, i) => {
    return !![
      heaven === "乙" && earth === "辛" && door === "休" && i === 6,
      heaven === "乙" && door === "生" && i === 6,
      heaven === "庚" && door === "開" && i === 5,
      heaven === "辛" && door === "生" && i === 6,
    ].find(o => o)
  },
  神遁: ({ heaven, earth, door, god, star }) =>
    (heaven === "丙" && god === "天" && door === "生") ||
    (heaven === "乙" && god === "天" && door === "休"),
  鬼遁: ({ heaven, earth, door, god, star }, i) => {
    return !![
      heaven === "乙" && earth === "杜" && god === "地",
      heaven === "乙" && door === "生" && i === 6 && god === "地",
      heaven === "乙" && door === "開" && god === "地",
      heaven === "丁" && door === "開" && god === "地",
      heaven === "丁" && door === "休" && god === "地",
      heaven === "丁" && door === "生" && god === "地",
    ].find(o => o)
  },
  日奇得使: ({ heaven, earth, door, god, star }, i) => {
    return !![
      heaven === "乙" && i === 8,
      heaven === "乙" && i === 1,
      heaven === "乙" && earth === "己",
    ].find(o => o)
  },
  月奇得使: ({ heaven, earth, door, god, star }, i) => {
    return !![
      (heaven === "丙" && i === 7) || i === 2,
      heaven === "丙" && i === 1,
      heaven === "乙" && earth === "戊",
    ].find(o => o)
  },
  星奇得使: ({ heaven, earth, door, god, star }, i) => {
    return !![
      (heaven === "丁" && i === 0) || i === 6,
      heaven === "丁" && earth === "壬",
    ].find(o => o)
  },
  日奇昇殿: ({ heaven, earth, door, god, star }, i) => {
    return !![heaven === "乙" && i === 3].find(o => o)
  },
  月奇昇殿: ({ heaven, earth, door, god, star }, i) => {
    return !![heaven === "丙" && i === 1].find(o => o)
  },
  星奇昇殿: ({ heaven, earth, door, god, star }, i) => {
    return !![heaven === "丁" && i === 5].find(o => o)
  },
  真詐: ({ heaven, earth, door, god, star }, i) => {
    return !![
      heaven === "乙" && door === "休" && i === 6 && god === "陰",
      heaven === "丙" && door === "生" && i === 6 && god === "陰",
      heaven === "丁" && door === "開" && i === 6 && god === "陰",
    ].find(o => o)
  },
  重詐: ({ heaven, earth, door, god, star }, i) => {
    return !![
      heaven === "乙" && door === "休" && i === 6 && god === "地",
      heaven === "丙" && door === "生" && i === 6 && god === "地",
      heaven === "丁" && door === "開" && i === 6 && god === "地",
    ].find(o => o)
  },
  休詐: ({ heaven, earth, door, god, star }, i) => {
    return !![
      heaven === "乙" && door === "休" && i === 6 && god === "合",
      heaven === "丙" && door === "生" && i === 6 && god === "合",
      heaven === "丁" && door === "開" && i === 6 && god === "合",
    ].find(o => o)
  },
  天假: ({ heaven, earth, door, god, star }, i) => {
    return !![
      heaven === "乙" && door === "景" && god === "天",
      heaven === "丙" && door === "景" && god === "天",
      heaven === "丁" && door === "景" && god === "天",
    ].find(o => o)
  },
  地假: ({ heaven, earth, door, god, star }, i) => {
    return !![
      ["丁", "己", "癸"].includes(heaven) &&
        door === "杜" &&
        ["地", "陰", "合"].includes(god),
    ].find(o => o)
  },
  人假: ({ heaven, earth, door, god, star }, i) => {
    return !![
      heaven === "壬" && door === "驚" && god === "天",
      ["丁", "己", "癸"].includes(heaven) && door === "杜" && god === "陰",
    ].find(o => o)
  },
  神假: ({ heaven, earth, door, god, star }, i) => {
    return !![
      heaven === "壬" && door === "驚" && god === "天",
      ["丁", "己", "癸"].includes(heaven) &&
        door === "傷" &&
        ["地", "陰", "合"].includes(god),
    ].find(o => o)
  },
  鬼假: ({ heaven, earth, door, god, star }, i) => {
    return !![
      ["丁"].includes(heaven) &&
        door === "死" &&
        ["地", "陰", "合"].includes(god),
      ["己"].includes(heaven) && door === "死" && ["陰"].includes(god),
      ["癸"].includes(heaven) && door === "死" && ["地"].includes(god),
    ].find(o => o)
  },
  日旺: ({ heaven, earth, door, god, star }, i) => {
    return !![["乙"].includes(heaven) && i === 3].find(o => o)
  },
  交泰格: ({ heaven, earth, door, god, star }, i) => {
    return !![["乙"].includes(heaven) && ["丙", "丁"].includes(earth)].find(
      o => o
    )
  },
  天遇昌氣格: ({ heaven, earth, door, god, star }, i) => {
    return !![["丁"].includes(heaven) && ["乙"].includes(earth)].find(o => o)
  },
  雀含花格: ({ heaven, earth, door, god, star }, i) => {
    return !![["丙"].includes(heaven) && ["乙"].includes(earth)].find(o => o)
  },
  三奇之靈: ({ heaven, earth, door, god, star }, i) => {
    return !![
      ["乙"].includes(heaven) &&
        door === "休" &&
        ["地", "陰", "合"].includes(god),
      ["丙"].includes(heaven) &&
        door === "生" &&
        ["地", "陰", "合"].includes(god),
      ["丁"].includes(heaven) &&
        door === "開" &&
        ["地", "陰", "合"].includes(god),
    ].find(o => o)
  },
  星隨月轉: ({ heaven, earth, door, god, star }, i) => {
    return !![["丁"].includes(heaven) && earth === "丙"].find(o => o)
  },
  奇儀相合: ({ heaven, earth, door, god, star }, i) => {
    return !![["乙"].includes(heaven) && earth === "庚"].find(o => o)
  },
}
